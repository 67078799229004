import { Caption } from '@mediahuis/chameleon-react';
import cx from 'classnames';

import { DynamicIcon, type DynamicIconProps } from '@hubcms/ui-chameleon';

import { NavigationLink } from '../NavigationLink';

import styles from './menu-item.module.scss';

type MenuItemProps = {
  href: string;
  leftIconName?: DynamicIconProps['name'];
  rightIconName?: DynamicIconProps['name'];
  text: string;
  font?: 'default' | 'alt';
  variant?: 'primary' | 'secondary';
  target?: string;
  trackingBlock: string;
};

export function MenuItem({
  href,
  leftIconName,
  rightIconName,
  text,
  font = 'default',
  variant = 'primary',
  trackingBlock,
  target,
}: MenuItemProps) {
  return (
    <NavigationLink
      className={cx(styles.menuItem, styles[`${variant}MenuItem`])}
      href={href}
      trackingName={text}
      trackingBlock={trackingBlock}
      target={target}
    >
      {!!rightIconName && <DynamicIcon name={rightIconName} />}
      <Caption font={font} size="lg" weight="strong" className={styles.text}>
        {text}
      </Caption>
      {!!leftIconName && <DynamicIcon name={leftIconName} />}
    </NavigationLink>
  );
}
