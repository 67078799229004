import { Button, IconButton } from '@mediahuis/chameleon-react';
import { Close, MenuSearch } from '@mediahuis/chameleon-theme-wl/icons';

import { useNavigation } from '@hubcms/data-access-navigation';

import styles from './navigation-menu.module.scss';

type NavigationMenuProps = {
  as: 'Button' | 'IconButton';
  closeLabel: string;
  label: string;
};

export function NavigationMenu({ as, closeLabel, label }: NavigationMenuProps) {
  const { isAsideMenuOpen, toggleIsAsideMenuOpen } = useNavigation();

  if (as === 'IconButton') {
    return (
      <IconButton
        aria-label={label}
        className={styles.navigationMenuButton}
        data-testid="navigation-menu"
        icon={isAsideMenuOpen ? Close : MenuSearch}
        size="lg"
        onClick={toggleIsAsideMenuOpen}
      />
    );
  }

  return (
    <Button
      className={styles.navigationMenuButton}
      data-testid="navigation-menu"
      iconLeft={isAsideMenuOpen ? Close : MenuSearch}
      onClick={toggleIsAsideMenuOpen}
    >
      {isAsideMenuOpen ? closeLabel : label}
    </Button>
  );
}
