import { Logo } from '@mediahuis/chameleon-react';
import cx from 'classnames';

import type { NavigationLogoSize } from '@hubcms/domain-navigation';

import { NavigationLink } from '../NavigationLink';

import styles from './navigation-logo.module.scss';

const logoConfig: Record<NavigationLogoSize, { height: string }> = {
  xs: {
    height: 'var(--scale-7)',
  },
  sm: {
    height: 'var(--scale-9)',
  },
  md: {
    height: 'var(--scale-10)',
  },
  lg: {
    height: 'var(--scale-11)',
  },
};

type NavigationLogoProps = {
  logoName: string;
  size?: NavigationLogoSize;
  classNameLogo?: string;
  classNameLink?: string;
};

export function NavigationLogo({ logoName, size = 'md', classNameLogo, classNameLink }: NavigationLogoProps) {
  const config = logoConfig[size];

  return (
    <NavigationLink
      data-testid="navigation-logo"
      trackingName="home"
      trackingBlock="header-logo"
      href="/"
      className={classNameLink}
      style={{ height: config.height }}
    >
      <Logo className={cx(styles.navigationLogo, classNameLogo)} name={logoName} />
    </NavigationLink>
  );
}
