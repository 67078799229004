import cx from 'classnames';
import { useState } from 'react';

import { navigationConfig } from '@hubcms/brand';
import { useNavigation } from '@hubcms/data-access-navigation';
import { type NavItem } from '@hubcms/domain-navigation';
import { NavigationLogo, NavigationMenu } from '@hubcms/ui-navigation-2';
import { useScrollListener } from '@hubcms/utils-browser';

import { NavigationItem } from '../NavigationItem';
import { NavigationUserMenu } from '../NavigationUserMenu';
import { Subnav } from '../Subnav/Subnav';
import { SubscriptionButton } from '../SubscriptionButton';

import styles from './header.module.scss';

type HeaderMobileProps = {
  subscriptionItem?: NavItem;
  loginUrl: string;
  userMenuItems: NavItem[];
  topicItems: NavItem[];
};

export function HeaderMobile({ subscriptionItem, loginUrl, userMenuItems, topicItems }: HeaderMobileProps) {
  const [applyScrollClass, setApplyScrollClass] = useState(false);

  const { subnavItems, subnavTitle } = useNavigation();
  const subnavProps =
    topicItems.length > 0
      ? { title: '', items: topicItems, trackingBlock: 'topics' }
      : { title: subnavTitle, items: subnavItems };

  useScrollListener((scrollY, prevScrollY) => {
    if (scrollY === 0) {
      setApplyScrollClass(false);
    }
    if (scrollY > 0 && prevScrollY === 0) {
      setApplyScrollClass(true);
    }
  });

  return (
    <div className={cx(styles.headerMobile, { [styles.scroll]: applyScrollClass })} data-testid="header-mobile">
      <div className={styles.main}>
        <NavigationMenu as="IconButton" closeLabel={navigationConfig.menuCloseLabel} label={navigationConfig.menuLabel} />

        <span className={cx(styles.topContainer, { [styles.scroll]: applyScrollClass })}>
          <NavigationLogo
            classNameLogo={styles.logo}
            logoName={navigationConfig.logoMobile}
            size={navigationConfig.logoMobileSize}
          />
        </span>

        <div
          className={cx(styles.scrollContainer, {
            [styles.scroll]: applyScrollClass,
            [styles.center]: !subscriptionItem,
            [styles.split]: subscriptionItem,
          })}
        >
          <NavigationLogo
            classNameLogo={styles.logo}
            logoName={navigationConfig.logoMobileScroll || navigationConfig.logoMobile}
            size={navigationConfig.logoMobileSize}
            classNameLink={cx({ [styles.logoLink]: !navigationConfig.logoMobileScroll })}
          />
          {subscriptionItem && (
            <NavigationItem navItem={subscriptionItem}>
              {({ href }) => <SubscriptionButton href={href} text={subscriptionItem.text} />}
            </NavigationItem>
          )}
        </div>

        <NavigationUserMenu items={userMenuItems} size="sm" loginUrl={loginUrl} />
      </div>
      {subnavProps.items.length > 0 && (
        <Subnav
          testId="header-mobile-subnav"
          className={cx(styles.subnav, { [styles.scroll]: applyScrollClass })}
          isScrolling={applyScrollClass}
          {...subnavProps}
        />
      )}
    </div>
  );
}
